import { v4 as uuidv4 } from 'uuid';
const OSS = require('ali-oss');
import request from '@/utils/request'
const bucket = 'rcmtoss'     // newhboss | rcmtoss
export const getExtname = (str) => {
    if (!str) {
        return ''
    }
    const pointIndex = str.lastIndexOf('.')
    return str.substring(pointIndex)
}
/* 获取oss文件上传参数 */
export const getOSSParams = (params) => {
    return request.get('/interface/file/sts/upload', params)
}
/* 小文件上传没有上传进度 */
export const uploadSmallFile = (file, ossParams, type) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (!ossParams) {
                ossParams = await getOSSParams()
            }
            const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = ossParams;
            let ossClient = new OSS({
                accessKeyId,
                accessKeySecret,
                stsToken,
                bucket,
                secure: true,
                folder: "",
                region: 'oss-cn-beijing',
                refreshSTSTokenInterval: 4 * 60 * 1000,
                refreshSTSToken: async () => {
                    const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
                    return {
                        accessKeyId,
                        accessKeySecret,
                        stsToken,
                    };
                },
            });
            try {
                console.log('getExtname: ', getExtname(file.name))
                const extname = getExtname(file.name)
                let path = `shufaInner/sysFile/${uuidv4()}${extname}`
                if(type == 'words'){
                    path = `shufaInner/words/${uuidv4()}${extname}`
                }
                const result = await ossClient.put(
                    path,
                    file,
                    {
                        timeout: 5 * 60 * 1000,
                    },
                );
                console.log('ossClient.put: ', result);
                resolve({ url: result.url, fileName: file.name })
            } catch (err) {
                console.log('ossClient.put err: ', err);
                reject(err)
            }
        } catch (err) {
            console.err('uploadSmallFile err: ', err)
            reject(err)
        }
    })
}
/* 简单逻辑大文件上传，包含上传进度 */
export const uploadBigFile = async (file, processCallback) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
            let ossClient = new OSS({
                accessKeyId,
                accessKeySecret,
                stsToken,
                bucket,
                secure: true,
                folder: "",
                region: 'oss-cn-beijing',
                refreshSTSTokenInterval: 4 * 60 * 1000,
                refreshSTSToken: async () => {
                    const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
                    return {
                        accessKeyId,
                        accessKeySecret,
                        stsToken,
                    };
                },
            });
            try {
                console.log('getExtname: ', getExtname(file.name))
                const extname = getExtname(file.name)
                const path = `shufaInner/sysFile/${uuidv4()}${extname}`
                const options = {
                    timeout: 50 * 60 * 1000,
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        if (processCallback && typeof processCallback == 'function') {
                            processCallback(p)
                        }
                    },
                    parallel: 4,
                    partSize: 1024 * 1024,
                };
                const result = await ossClient.multipartUpload(
                    path,
                    file,
                    options,
                );
                console.log('multipartUpload: ', result);
                resolve({ url: result.res.requestUrls[0].split('?')[0], fileName: file.name })
            } catch (err) {
                console.error('multipartUpload err: ', err);
                reject(err)
            }
        } catch (err) {
            console.error('multipartUpload err: ', err)
            reject(err)
        }
    })
}
/* 复杂逻辑大文件上传，包含上传进度，中断上传继续上传 */
export class multipartUploadFile {
    constructor() {
        this.ossClient = null
        this.checkpoint = null
        this.processCallback = null
        this.successCallback = null
        this.failCallback = null
        this.fileName = null
        this.path = null
        this.file = null
        this._isPause = false
        this._isAbort = false
    }
    // 开始上传
    async upload(file, processCallback, successCallback, failCallback) {
        this.processCallback = processCallback
        this.successCallback = successCallback
        this.failCallback = failCallback
        this.file = file
        this.fileName = file.name
        try {
            const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
            this.ossClient = new OSS({
                accessKeyId,
                accessKeySecret,
                stsToken,
                bucket,
                secure: true,
                folder: "",
                region: 'oss-cn-beijing',
                refreshSTSTokenInterval: 4 * 60 * 1000,
                refreshSTSToken: async () => {
                    const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
                    return {
                        accessKeyId,
                        accessKeySecret,
                        stsToken,
                    };
                },
            });
            try {
                console.log('getExtname: ', getExtname(file.name))
                const extname = getExtname(file.name)
                this.path = `shufaInner/sysFile/${uuidv4()}${extname}`
                const options = {
                    timeout: 5 * 60 * 1000,
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        this.checkpoint = cpt
                        if (this.processCallback && typeof this.processCallback == 'function') {
                            this.processCallback(p)
                        }
                    },
                    parallel: 4,
                    partSize: 1024 * 1024,
                };
                const result = await this.ossClient.multipartUpload(
                    this.path,
                    this.file,
                    options,
                );
                console.log('multipartUpload: ', result);
                if (this.successCallback && typeof this.successCallback == 'function') {
                    this.successCallback({ url: result.res.requestUrls[0].split('?')[0], name: this.fileName })
                }
            } catch (err) {
                console.error('multipartUpload err: ', err);
                if (this._isAbort || this._isPause) {
                    return
                }
                this._isPause = true
                let errMessage
                if (err.code === 'ConnectionTimeoutError') {
                    console.log("Woops,Woops ,timeout error!!!");
                    errMessage = {
                        type: 'ConnectionTimeoutError'
                    }
                } else if (err.code == 'RequestError') {
                    errMessage = {
                        type: 'RequestError'
                    }
                } else if (this.ossClient.isCancel()) {
                    errMessage = {
                        type: 'cancel'
                    }
                } else if (err.name === 'abort') {
                    errMessage = {
                        type: 'abort'
                    }
                }
                if (this.failCallback && typeof this.failCallback == 'function') {
                    this.failCallback(errMessage ? errMessage : err)
                }
            }
        } catch (err) {
            console.err('multipartUpload err: ', err)
            if (this.failCallback) {
                this.failCallback(err)
            }
        }
    }
    // 暂停上传
    cancel() {
        if (!this.ossClient) {
            return
        }
        this.ossClient.cancel()
        this._isPause = true
        if (this.failCallback && typeof this.failCallback == 'function') {
            this.failCallback({
                type: 'cancel'
            })
        }
    }
    // 中断上传
    abort() {
        if (!this.ossClient && !this.checkpoint) {
            return
        }
        this.ossClient.abortMultipartUpload(this.checkpoint.name, this.checkpoint.uploadId)
        this._isAbort = true
        if (this.failCallback && typeof this.failCallback == 'function') {
            this.failCallback({
                type: 'abort'
            })
        }
    }
    // 继续上传
    async resume() {
        if (!this.ossClient && !this.checkpoint) {
            return
        }
        try {
            const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
            this.ossClient = new OSS({
                accessKeyId,
                accessKeySecret,
                stsToken,
                bucket,
                secure: true,
                folder: "",
                region: 'oss-cn-beijing',
                refreshSTSTokenInterval: 4 * 60 * 1000,
                refreshSTSToken: async () => {
                    const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken } = await getOSSParams();
                    return {
                        accessKeyId,
                        accessKeySecret,
                        stsToken,
                    };
                },
            });
            try {
                const options = {
                    timeout: 5 * 60 * 1000,
                    checkpoint: this.checkpoint,
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        this.checkpoint = cpt
                        if (this.processCallback && typeof this.processCallback == 'function') {
                            this.processCallback(p)
                        }
                    },
                    parallel: 4,
                    partSize: 1024 * 1024,
                };
                const result = await this.ossClient.multipartUpload(
                    this.path,
                    this.file,
                    options,
                );
                console.log('multipartUpload: ', result);
                if (this.successCallback && typeof this.successCallback == 'function') {
                    this.successCallback({ url: result.res.requestUrls[0].split('?')[0], name: this.fileName })
                }
            } catch (err) {
                console.error('multipartUpload err: ', err);
                if (this._isAbort || this._isPause) {
                    return
                }
                this._isPause = true
                let errMessage
                if (err.code === 'ConnectionTimeoutError') {
                    console.log("Woops,Woops ,timeout error!!!");
                    errMessage = {
                        type: 'ConnectionTimeoutError'
                    }
                } else if (err.code == 'RequestError') {
                    errMessage = {
                        type: 'RequestError'
                    }
                } else if (this.ossClient.isCancel()) {
                    errMessage = {
                        type: 'cancel'
                    }
                } else if (err.name === 'abort') {
                    errMessage = {
                        type: 'abort'
                    }
                }
                if (this.failCallback && typeof this.failCallback == 'function') {
                    this.failCallback(errMessage ? errMessage : err)
                }
            }
        } catch (err) {
            console.err('multipartUpload err: ', err)
            if (this.failCallback && typeof this.failCallback == 'function') {
                this.failCallback(err)
            }
        }
    }
}
/* 小文件批量上传 */
export const batchUploadSmallFile = async (fileList) => {
    try {
        const ossParams = await getOSSParams()
        const pList = []
        for (let i = 0, len = fileList.length; i < len; i++) {
            pList.push(uploadSmallFile(fileList[i], ossParams))
        }
        return Promise.all(pList)
    } catch (err) {
        return Promise.reject(err)
    }
}
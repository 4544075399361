import axios from 'axios'
import * as JSZip from 'jszip';
const CancelToken = axios.CancelToken;
const XLSX = require("xlsx");
import { uploadSmallFile } from '@/utils/ossUploadFile'
export const uploadFileTask = (url, formData, call, cancelFunc) => {
    return axios.post(url, formData, {
        timeout: 1000 * 60 * 60 * 24,
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: new CancelToken(function executor(cancel) {
            cancelFunc(cancel)
        }),
        onUploadProgress: function (e) {
            if (e.lengthComputable) {
                call(Math.round(e.loaded / e.total * 100))
            }
        }
    })
}
export const computeFileSize = () => {

}
/* 获取字节数 */
export const getBytesLength = (a) => {
    var b = 0;
    for (var i = a.length; i-- > 0;) {
        // 一个中文按3个字节
        if (a[i].charCodeAt() > 255) {
            b += 3;
        } else {
            b++;
        }
    }
    return b;
}

export const browerDownloadFile = ({ fileUrl, fileName }) => {
    let httpRequest = new XMLHttpRequest();
    //指定响应类型，这决定了浏览器对返回内容的解析方式，设置为空或者text会作为字符解析、json会作为json解析，blob和arraybuffer会作为字节流解析
    httpRequest.responseType = 'arraybuffer';
    httpRequest.open('GET', fileUrl.replace(/^http:/, 'https:') + '?response-content-type=application/octet-stream', true);
    httpRequest.onload = () => {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
            //只有responseType为空或者text，才会使用responseText获取内容，其他情况                        httpRequest.response就是你需要的不含http头的返回内容
            let content = httpRequest.response;
            let link = document.createElement('a');
            link.download = fileName;

            link.style.display = 'none';
            let blob = new Blob([content]);
            //创建指向内存中字节流的链接
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    httpRequest.send();
    // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为

}


/* 数据导出ecxcel [[111, 222, 333], [444, 555, 666]] */
export const dataToExcel = (data, fileName) => {
    const ws_name = "SheetJS";
    const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, fileName);
}

/* table元素导出excel */
export const tableToExcel = (tableDom, fileName) => {
    const wb = XLSX.utils.table_to_book(tableDom, { sheet: "Sheet JS" });
    XLSX.writeFile(wb, fileName);
}
/* 获取文件数据 */
export const getFile = (url) => {
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: 'get',
            responseType: 'blob',
            timeout: 10 * 60 * 1000,
        }).then(data => {
            resolve(data.data)
        }).catch(err => {
            reject(err.toSring())
        })
    })
}
/* 下载文件 */
export const downloadFile = (data, fileName) => {
    let url 
    if(/^http/i.test(data)){
        url = data
    }else{
        url = window.URL.createObjectURL(data)
    }
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
}
/* 提取excel中的字体 */
export const importExcelWordList = (fileList) => {
    return new Promise((resolve, reject) => {
        let total = fileList.length, successNum = 0, errorNum = 0
        let wordList = []
        for (let i = 0; i < total; i++) {
            const fileReader = new FileReader()
            const file = fileList[i]
            fileReader.readAsBinaryString(file)
            fileReader.onload = (e) => {
                try {
                    const data = e.target.result
                    const workbook = XLSX.read(data, {
                        type: 'binary'
                    })
                    let fromTo = ''
                    let sheetContent = []
                    for (let sheet in workbook.Sheets) {
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            fromTo = workbook.Sheets[sheet]['!ref']
                            sheetContent.push(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { header: 1 }))
                        }
                    }
                    sheetContent.forEach(item => {
                        item.forEach(subItem => {
                            subItem.forEach( sSubItem => {
                                if (sSubItem) {
                                    sSubItem = String(sSubItem).trim()
                                    if (sSubItem) {
                                        sSubItem = sSubItem[0]
                                        if (!wordList.includes(sSubItem)) {
                                            wordList.push(sSubItem)
                                        }
                                    }
                                }
                            })
                            
                        })
                    })
                    successNum++
                } catch (err) {
                    errorNum++
                    console.error("getExcelWordList err: ", err)
                }
                if (successNum + errorNum == total) {
                    resolve({
                        wordList,
                        total,
                        successNum,
                        errorNum,
                    })
                }
            }
        }
    })
}
/* 导出excel中的字体 */
export const exportExcelWordList = (data, fileName) => {
    return new Promise((resolve, reject) => {
        try{
            const ws_name = "SheetJS";
            const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            XLSX.writeFile(wb, fileName);
            resolve('success')
        }catch(err){
            reject(err)
        }
    })
}
/* 上传选中的字体视频 */
export const importWordVideoList = (fileList, callbackFunc) => {
    return new Promise((resolve, reject) => {
        let total = fileList.length, successNum = 0, errorNum = 0, uploadIndex = 0, uploadingNum = 0
        let videoList = []
        fileList.forEach(item => {
            videoList.push({
                name: item.name.replace(/\.mp4$/i, ''),
                file: item,
                url: '',
            })
        })
        async function videoUpload() {
            uploadingNum++
            let tempIndex = uploadIndex
            try {
                const { url } = await uploadSmallFile(videoList[tempIndex].file, null, 'words')
                videoList[tempIndex].url = url
                uploadIndex++
                successNum++
                uploadingNum--
            } catch (err) {
                uploadIndex++
                errorNum++
                uploadingNum--
                console.error('uploadSmallFile err: ', err)
            }
            if (errorNum + successNum == total) {
                let resList = []
                videoList.forEach(item => {
                    if (item.url) {
                        resList.push({
                            name: item.name,
                            videoUrl: item.url
                        })
                    }
                })
                resolve({
                    errorNum,
                    successNum,
                    total,
                    videoList: resList,
                })
            } else if (errorNum + successNum + uploadingNum != total && uploadingNum < 4) {
                videoUpload()
            }
            if (typeof callbackFunc == 'function') {
                callbackFunc({
                    total,
                    successNum,
                    errorNum,
                })
            }
        }
        callbackFunc({
            total,
            successNum,
            errorNum,
        })
        videoUpload()
    })
}
/* 导出选中的字体视频 */
export const exportWordVideoList = (fileList, callbackFunc) => {
    return new Promise((resolve, reject) => {
        let total = fileList.length, successNum = 0, errorNum = 0, downIndex = 0, downingNum = 0
        const zip = new JSZip()
        async function videoDown() {
            downingNum++
            let tempIndex = downIndex
            try {
                const { fileName, url } = fileList[tempIndex]
                const fileData = await getFile(url)
                console.log('fileData: ', fileData)
                zip.file(fileName, fileData)
                downIndex++
                successNum++
                downingNum--
            } catch (err) {
                downIndex++
                errorNum++
                downingNum--
                console.error('exportWordVideoList err: ', err)
            }
            if (errorNum + successNum == total) {
                if (Object.keys(zip.files).length > 0) {
                    zip.generateAsync({ type: 'blob' }).then(blob => {
                        downloadFile(blob, `字体视频_${new Date().getTime()}.zip`)
                        resolve({
                            errorNum,
                            successNum,
                            total,
                        })
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    resolve({
                        errorNum,
                        successNum,
                        total,
                    })
                }
            } else if (errorNum + successNum + downingNum != total && downingNum < 10) {
                videoDown()
            }
            if (typeof callbackFunc == 'function') {
                callbackFunc({
                    total,
                    successNum,
                    errorNum,
                })
            }
        }
        callbackFunc({
            total,
            successNum,
            errorNum,
        })
        videoDown()
    })
}